<script>
  import "cross-fetch/polyfill";
  import Promise from "es6-promise";
  import Slider from "./slider/slider.svelte";
  import About from "./info/about.svelte";
  import Contact from "./info/contact.svelte";
  import Art from "./info/art.svelte";
  import { api } from "./utils/api.svelte";
  import * as Sentry from "@sentry/browser";

/*
  Sentry.init({
    dsn: "https://e552ba34740748798624596ca20144f7@sentry.io/2806936"
  });
*/
  let menuExpanded = false;

  let name = "world";

  let selectedSlide = {};
  let timeout = undefined;
  let nextSlideTimeout = undefined;
  let slides = [];
  let slider;

  let queryBackOff = false;
  let upNextData = {};
  let state = "home";
  let selectFirstSlide;
  let lastInteraction = Date.now() -1000000; //set last interaction in past.

  function selectHome() {
    state = "home";
    selectedSlide = {};

    selectArt(slides[0]);
  }

  function selectAbout() {
    state = "about";
    selectedSlide = {};

  }

  function selectContact() {
    state = "contact";
    selectedSlide = {};

  }

  function getFixedDate(dateString){
      return new Date(dateString.replace(/ /g,"T")+'Z');
  }

  function getPlaybackSequence() {
    return api
      .get(
        "https://mpcas.10net.net/api/v1/playouts/upcoming-playouts?format=json"
      )
      .then(result => {
        slides = result;
        if (result.length > 0) {
         // console.log('before');
         // console.log(slides);
        
         console.log('-----------updated upcoming playouts');
         console.log('slide 0: '+ slides[0].mediaitem_name + ' start_time: ' + getFixedDate(slides[0].start_time));
         console.log('slide 1: '+ slides[1].mediaitem_name + ' start_time: ' + getFixedDate(slides[1].start_time));
          var currentTime = new Date();
          var maxTime = currentTime;
          var minTime = getFixedDate(slides[0].start_time);
          console.log(slides[0].start_time);
          console.log(minTime);
          //console.log (new Date(slides[0].start_time.replace(/ /g,"T")+'Z'));
          // Calculate time difference and set to update when its hit
          slides.forEach((slide, index) => {
            slide.id = index;
            const time = getFixedDate(slide.start_time); //Z sets timezone to UTC instead of local time
            if (time > maxTime) {
              maxTime = time;
            }
          });
          var minTime = maxTime;
          slides.forEach((slide, index) => {
            slide.id = index;
            const time = getFixedDate(slide.start_time); 
            if (time < minTime && (time.getTime()-currentTime.getTime() > 0)) {  
              //console.log('found mintime in slide ' + index)          ;
              minTime = time;
            }
          });
          //selectedSlide = slides[0];
          if (slides.length > 1) {
            upNextData = slides[1];
          } else {
            upNextData = {};
          }

          var timeDistance =   getFixedDate(slides[1].start_time).getTime() -currentTime.getTime();
          //timeDistance = 10000;
          console.log('mililseconds to end: ' + timeDistance);
          if (timeDistance > 0) {
            clearTimeout(nextSlideTimeout);
            //specificaly timed fetch to coincide with media player change
            nextSlideTimeout = setTimeout(getPlaybackSequence, timeDistance +1000);
          }
          //console.log('after');
         console.log(selectedSlide);
         //select previously selected artwork after slides updated
          var found =false;
         slides.forEach((slide, index) => {          
           if(slide.mediaitem_name == selectedSlide.mediaitem_name && !found){
             selectArt(slides[index]);
             selectedSlide=slides[index];
             found=true;
           }
         });

         //if interaction has timed out, or old item is not viewable, select playing now slide
         if(!found || Date.now() - 120000 > lastInteraction){
            //else select current playing slide
            selectArt(slides[0]);
            selectedSlide=slides[0];
           //selectArt(slides[0]);
         }
        }
        
        return Promise.resolve();
      });
  }

  // Every minute, update slide data to catch changes in playlist.
  setInterval(() => {
    getPlaybackSequence();
    console.log("one minute api refresh");
  }, 60000);

  getPlaybackSequence().then(() => {
   // console.log("slides retrieved:");
   // console.log(slides);
    if(slides.length > 0){
    selectArt(slides[0]);
    } else {
      selectAbout();
    }
  });

  function selectSlide(event) {
    selectArt(event.detail);
  }

  function selectArt(art) {
      selectedSlide =  art;
      state='art';
  }

  function toggleMenu() {
    menuExpanded = !menuExpanded;
  }

  // Refresh page every 6hrs to prevent issues stacking up
  setTimeout(() =>{
    location.reload();
  }, 1000 * 60 * 60 * 6);

  //update last interaction & after 2 minutes of no interaction, reset to home page
  document.addEventListener('click', ()=>{
    lastInteraction= Date.now();
     clearTimeout(timeout);
    timeout = setTimeout(() => {
      if(slides > 0){
      selectHome();
      timeout = undefined;
      selectedSlide = {};
      }
    }, 120000);
  })
</script>

<style>:global(html),
:global(body) {
  color: white;
  padding: 0;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  overflow-x: hidden;
  /*background: gray;*/
  font-size: 8px;
}
:global(html) ::-webkit-scrollbar,
:global(body) ::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
:global(html) ::-webkit-scrollbar-track,
:global(body) ::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(240, 240, 240, 0.1);
}
:global(html) ::-webkit-scrollbar-thumb,
:global(body) ::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(150, 150, 150, 0.8);
}
:global(html) ::-webkit-scrollbar-thumb:window-inactive,
:global(body) ::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(200, 200, 200, 0.4);
}
header {
  position: fixed;
  right: 0;
  z-index: 20;
}
header ul {
  list-style: none;
  padding: 0;
}
header ul li {
  overflow: hidden;
  padding: 10px;
  margin: 0 10px;
  text-transform: uppercase;
  display: inline-block;
  font-size: 22px;
  font-weight: normal;
  cursor: pointer;
  user-select: none;
  border-bottom: 3px solid transparent;
  transition: border-bottom 0.5s, height 0.5s, padding 0.5s, opacity 0.5s;
}
header ul li.selected {
  border-bottom: 3px solid white;
}
header ul li.burger {
  display: none;
}
@media (max-width: 992px) {
  header ul {
    flex-direction: column;
    display: flex;
    transition: box-shadow 0.5s, background 0.5s;
    padding: 0 0 10px 10px;
  }
  header ul.visible {
    box-shadow: 0 0 10px 7px rgba(0, 0, 0, 0.5);
    background: rgba(0, 0, 0, 0.5);
  }
  header ul.visible li {
    height: 27px;
    padding: 10px;
    opacity: 1;
  }
  header ul li {
    height: 0;
    padding: 0;
    opacity: 0;
    border-width: 0;
    text-align: right;
  }
  header ul li.selected {
    border-width: 0;
    font-weight: bold;
  }
  header ul li.burger {
    height: 27px;
    padding: 10px;
    opacity: 1;
    display: block;
  }
}
</style>

<svelte:head>
  <link
    href="https://fonts.googleapis.com/css?family=Montserrat:300,400,600&display=swap"
    rel="stylesheet" />

</svelte:head>

<header>
  <ul class={menuExpanded ? 'visible' : ''}>
    <li class="burger" on:click={toggleMenu}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        height="32px"
        style="fill:white"
        version="1.1"
        viewBox="0 0 32 32"
        width="32px"
        xml:space="preserve">
        <path
          d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z
          M28,14H4c-1.104,0-2,0.896-2,2
          s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z
          M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2
          S29.104,22,28,22z" />
      </svg>
    </li>
    <li class={state == 'home' ? 'selected' : ''} on:click={selectHome}>
      Home
    </li>
    <li class={state == 'about' ? 'selected' : ''} on:click={selectAbout}>
      About Us
    </li>
    <li class={state == 'contact' ? 'selected' : ''} on:click={selectContact}>
      Contact
    </li>
  </ul>
</header>

<Slider
  bind:selectFirstSlide
  visible={state == 'home' || state == 'art' }
 
  bind:slides={slides}
  {selectedSlide}
  on:selectSlide={selectSlide} />

<About visible={state == 'about'} />
<Contact visible={state == 'contact'} />
<Art
  visible={state == 'art'}
  data={selectedSlide}
  {upNextData}
  live={selectedSlide.id == 0} />
