<script type="text/javascript">
  import { beforeUpdate, afterUpdate, onMount } from "svelte";

  export let visible;
  export let data;
  export let upNextData;
  export let live;

  let artistsText;
  let photo;
  beforeUpdate(() => {
    if (typeof data.artwork !== "undefined") {
      //console.log("Art Component artwork data:");
      //console.log(data.artwork);
      artistsText = data.artwork.artists
        .map(a => {
          return `${a.name}`;
        })
        .join(", ");
      photo = data.artwork.photo_main;
    }
    /*
    if (typeof data.program !== "undefined") {
      console.log("Art Component program data:");
      console.log(data.program);
      artistsText = data.program.artist;
      photo = data.program.photo_main;
    }
    */
  });
</script>

<style type="text/css">::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(240, 240, 240, 0.1);
}
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(150, 150, 150, 0.8);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(200, 200, 200, 0.4);
}
.background {
  z-index: -1;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  min-height: 100vh;
  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: multiply;
  -ms-overflow-style: none;
  transition: opacity 0.5s;
}
.background.visible {
  opacity: 1;
  z-index: 10;
}
.text {
  padding: 4vh 4vw 0 4vw;
  opacity: 0;
  z-index: 3;
  transition: opacity 0.5s;
  text-shadow: 2px 2px 4px #000000;
}
.text.visible {
  opacity: 1;
}
.text h1 {
  font-size: 24px;
  margin: 10px 0;
  font-weight: 600;
  text-transform: capitalize;
}
.text h2 {
  font-size: 18px;
  margin: 15px 0;
  font-weight: 200;
  font-weight: 400;
}
.text p {
  font-size: 14px;
  margin: 10px 0;
  font-weight: 300;
  line-height: 1.55em;
}
@media (min-width: 768px) {
  .text h1 {
    font-size: 32px;
    margin: 20px 0;
  }
  .text h2 {
    font-size: 20px;
    margin-bottom: 12px;
  }
  .text h3 {
    font-size: 16px;
    margin-bottom: 22px;
  }
  .text p {
    font-size: 16px;
    width: 66vw;
    margin: 20px 0;
  }
}
@media (min-width: 992px) {
  .text h1 {
    font-size: 48px;
    margin: 20px 0;
  }
  .text h2 {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .text p {
    font-size: 16px;
    width: 66vw;
    margin-bottom: 20px;
  }
}
.scrollPadding {
  /* ensures text can always be scrolled up higher than the slides */
  width: 100%;
  height: 25vh;
  /* TODO use slider height var*/
  margin-bottom: 4vh;
}
</style>

<div
  class="background {visible ? 'visible' : ''}"
  style="background-image: url({photo || null})">

  <div class="text {visible ? 'visible' : ''}">

    {#if data.artwork}
      <h1>{data.artwork.artwork_title}</h1>
      {#if artistsText}
        <h2>{artistsText}</h2>
      {/if}
      <h3>{data.artwork.year || ''} {data.artwork.medium || ''}</h3>
      <p>
        {@html data.artwork.description || ''}
      </p>
    {/if}

    {#if data.artist}
      <h2>About {data.artist.name || ''}</h2>
      <p>{data.artist.bio || ''}</p>
    {/if}

    {#if upNextData.artwork && live}
      <h2>
        <b>Up Next:</b>
        {upNextData.artwork.artwork_title || ''}
      </h2>
    {/if}

    <div class="scrollPadding" />
  </div>

</div>
