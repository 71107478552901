<script type="text/javascript">
  export let visible;
</script>

<style type="text/css">::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(240, 240, 240, 0.1);
}
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(150, 150, 150, 0.8);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(200, 200, 200, 0.4);
}
.background {
  z-index: 2;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  min-height: 100vh;
  opacity: 0;
  transition: opacity 0.5s;
  overflow-x: hidden;
  overflow-y: auto;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: multiply;
}
.background.visible {
  opacity: 1;
  z-index: 10;
}
.text {
  padding: 4vh 4vw 0 4vw;
  opacity: 0;
  transition: opacity 0.5s;
  z-index: 3;
}
.text.visible {
  opacity: 1;
}
.text a {
  color: white;
  text-decoration: underline;
}
.text h1 {
  font-size: 24px;
  margin: 10px 0;
  font-weight: 600;
  text-transform: capitalize;
}
.text h2 {
  font-size: 18px;
  margin: 15px 0;
  font-weight: 200;
  font-weight: 400;
}
.text p {
  font-size: 14px;
  margin: 10px 0;
  font-weight: 300;
  line-height: 1.55em;
}
@media (min-width: 768px) {
  .text h1 {
    font-size: 32px;
    margin: 20px 0;
  }
  .text h2 {
    font-size: 20px;
    margin: 30px 0;
  }
  .text p {
    font-size: 16px;
    width: 66vw;
    margin: 20px 0;
  }
}
@media (min-width: 992px) {
  .text h1 {
    font-size: 48px;
    margin: 20px 0;
  }
  .text h2 {
    font-size: 20px;
    margin: 30px 0;
  }
  .text p {
    font-size: 16px;
    width: 66vw;
    margin: 20px 0;
  }
}
</style>

<div
  class="background {visible ? 'visible' : ''}"
  style="">
  <div class="text {visible ? 'visible' : ''}">
    <h1>CONTACT US</h1>

<p><b>CONTACT</b><br>    
grunt gallery <br>
#116 – 350 East 2nd Avenue<br>
Vancouver, BC V5T 4R8<br>
</p>
<p><b>Web:</b> <a href="https://www.mpcas.ca/">https://www.mpcas.ca/</a></p>
<p><b>Email:</b> <a href="mailto:communications@grunt.ca"> communications@grunt.ca</a></p>
<p>Follow the MPCAS on <a href="https://www.facebook.com/MPCAS.Vancouver">Facebook</a> and <a href="https://www.instagram.com/mtpleasant_artscreen/?hl=en">Instagram</a> for news and updates!</p>
  </div>
</div>
