<script type="text/javascript">
  import Slide from "./slide.svelte";
  import { api } from "../utils/api.svelte";
  import { createEventDispatcher } from "svelte";
  import { beforeUpdate, afterUpdate, onMount } from "svelte";

  export let visible;
  export let selectedSlide;
  export let slides;
  export const sliderControl = {};
  const dispatch = createEventDispatcher();
  let sliderScroll;
  let isScrolling = false;

  // Slider desktop stuff
  let isDown = false;
  let startX;
  let scrollLeft;

  const mouseDown = e => {
    isDown = true;
    sliderScroll.classList.add("active");
    startX = e.pageX - sliderScroll.offsetLeft;
    scrollLeft = sliderScroll.scrollLeft;
  };

  const mouseLeave = e => {
    isDown = false;
    isScrolling = false;
    sliderScroll.classList.remove("active");
  };
  const mouseUp = e => {
    isDown = false;
    sliderScroll.classList.remove("active");
    if (isScrolling) {
      console.log("stopping propagation");
      e.stopPropagation();
      e.preventDefault();
    }
    setTimeout(function() {
      isScrolling = false;
    }, 5);
  };
  const mouseMove = e => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - sliderScroll.offsetLeft;
    const walk = x - startX;
    sliderScroll.scrollLeft = scrollLeft - walk;
    if (Math.abs(walk) > 3) {
      isScrolling = true;
    }
  };

   beforeUpdate(() => {
     //console.log('beforeupdate slider');

   });
</script>

<style>/* TODO - media queries for mobile*/
.slider-container ::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.slider-container ::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(240, 240, 240, 0.1);
}
.slider-container ::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(150, 150, 150, 0.8);
}
.slider-container ::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(200, 200, 200, 0.4);
}
.background {
  background: black;
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 1;
}
.background.visible {
  opacity: 1;
}
.bottom {
  position: fixed;
  left: 0;
  right: 0;
  bottom: -25vh;
  height: 25vh;
  background: rgba(0, 0, 0, 0.5);
  padding-left: 10px;
  overflow-x: auto;
  transition: bottom 0.5s;
  z-index: 100;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}
.bottom .slide-container {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 100%;
}
.bottom.visible {
  bottom: 0;
}
</style>

<div class="background {visible ? 'visible' : ''}" />

<div class="slider-container">
  <div
    class="bottom {visible ? 'visible' : ''}"
    on:mousedown={mouseDown}
    on:mouseup|capture={mouseUp}
    on:mouseleave={mouseLeave}
    on:mousemove={mouseMove}
    bind:this={sliderScroll}>
    <div class="slide-container">

      {#each slides as slide}
        <Slide
          on:selectSlide
          bind:data={slide}
          selected={slide.id == selectedSlide.id}
          live={slide.id == 0}
          {isScrolling} />
      {/each}
    </div>
  </div>
</div>
